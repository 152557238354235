import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import './layout.css'
import './mainContent.css'
import './Typist.css'

import SEO from "../components/seo"
import customImg from "../images/share_Erick.jpg"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            author
            url
            image
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          description ={data.site.siteMetadata.description}
          author ={data.site.siteMetadata.author}
          url ={data.site.siteMetadata.url}
          image ={data.site.siteMetadata.image}

          meta={[
            { name: 'description', content: data.site.siteMetadata.description },
            {
              property: `og:description`,
              content: data.site.siteMetadata.description,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:image`,
              content: customImg,
            },
            {
              name: `twitter:creator`,
              content: data.site.siteMetadata.author,
            },
            { name: 'author', content: data.site.siteMetadata.author},
            { name: 'url', content: data.site.siteMetadata.url},
          ]}
        >
          <html lang="en" />
        </Helmet>
          {children}

      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
